/* React */
import React, { Component }                 from "react";

/* Plugins */
import { connect  }                         from "react-redux";
import MediaQuery                           from "react-responsive";
import moment                               from "moment";

/* Redux */
import { mapAnddispatchActionsToProps, 
         hasInstallmentOverdue, 
         handleInputChange,
         getUserDetailsFromToken }                from "../../../__helpers/helpers";
import { UserExamsActions }                 from "../../../__actions/user_exams.actions";
import { EXAMS_PAGE, 
         PAGE_TITLE, 
         VIEW_WIDTH }                       from "../../../__config/constants";

/* Components */
import SubNavigationComponent               from "./../../global/components/sub_navigation.component";
import HeaderComponent                      from "./../../global/components/header.component";
import HeadComponent                        from "../../global/components/head.component";
import ExamContent                          from "./components/exam_content.component"

/* CSS */
import "./exam.scss";

/** 
* @class 
* @extends Component
* This component class is being called on the /layouts/user.layout.jsx <br>
* All methods are related to exam modules.<br>
* Last Updated Date: November 22, 2023
*/
class Exam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null, 
            exam: null,
            rules:{
                bootcamp_type: "Onsite",
                stacks: "Mean",
                general: [{
                            id:1,
                            name: "Unlocking",
                            description: "Exam codes must be acquired through your Instructor or TA, and may only be used once."
                        },
                        {
                            id:2,
                            name: "Time Limit",
                            description: "Must complete and upload your exam to the platform before your expired time."
                        },
                        {
                            id:3,
                            name: "Codes",
                            description: "If you push your code up to Github, you must set the repository to private and you may not post a video of the exam or any code on any other platform or site. Must complete and upload your exam to the platform before your expired time."
                        },
                        {
                            id:4,
                            name: "Concerns",
                            description: "Contact your Instructor or TA via email, Discord, or Mattermost for issues in taking the exam."
                        }]
               
            },
            user_code: "",
            valid_user_code: null,
            has_redirected_to_lp2: false,
            browse_and_drop_file_name: "",
            is_active_drag: false,
            is_form_processing: false,
            is_allowed_to_exam: false,
            is_submitting_exam_code: false
        }
    }      

    /**
    * DOCU: Check if the current logged-in user has an installment overdue and save the user session page. <br>
    * Triggered: This will trigger if the component is mounted. <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @author Christian, updated by Psyrone, Renz, Clifford
    */
    componentDidMount(){
        /* Redirect to the dashboard if the user has an installment overdue. */
        if(hasInstallmentOverdue()){
            window.location.href = "/dashboard";
        }

        /* This will check if the exams are still ongoing. */
        this.props.getExamDetails();

        this.props.onsaveUserSessionPage({page_type_id: EXAMS_PAGE});
    }

    /**
    * DOCU: This will update the state upon loading the page.<br>
    * Triggered: When changes happened in the exam page on the student side. <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @param {object} prevProps 
    * @param {object} prevState
    * @author Renz updated by Clifford
    */
    componentDidUpdate = (prevProps, prevState) => {
        let get_user_details = getUserDetailsFromToken();

        /* This will set the exam details for the user. */
        if(!this.state.exam && Object.keys(this.props.user_exams.exam_details).length){
            this.setState({ exam: this.props.user_exams.exam_details });
        }
        
        /* This will set the details of the profile. */
        if(get_user_details && !this.state.profile){
            this.setState((prevState) => ({
                ...prevState.profile,
                profile: get_user_details.user_details
            }));
        }

        /* This will display the exam content from the user session. */
        if(!this.state.valid_user_code && prevState.valid_user_code === this.state.valid_user_code && prevState.exam){
            let { file_name } = this.props?.user_exams?.exam_details;

            /* Update the state to display the exam questionnaire. */
            this.setState({ is_allowed_to_exam: true, valid_user_code: true, browse_and_drop_file_name: file_name || "" });
        }

        /* This will validate the submitted exam code and unlock the exam. */
        if(this.state.user_code && JSON.stringify(prevProps.user_exams) !== JSON.stringify(this.props.user_exams)){
            let { is_allowed_to_exam, valid_user_code } = this.state;

            /* Check if the details of the submitted exam code exists. */
            if(Object.keys(this.props.user_exams.exam_details)?.length || this.props.user_exams.exam_details.length){
                let { due_date, is_already_unlocked } = this.props.user_exams.exam_details;

                /* Check if the exam is ongoing and hasn't been unlocked yet. */
                if(due_date === "N/A" || (!is_already_unlocked && moment(due_date) > moment(moment.utc(new Date(), 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm')))){
                    /* Remove the error message. */
                    this.props.user_exams.exam_code_error = null;
                    
                    /* Hide the UI for errors. */
                    valid_user_code = true;

                    /* Display the exam UI. */
                    is_allowed_to_exam = true;
                }
                else{
                    is_allowed_to_exam = false;
                    valid_user_code = false;
                }
            }
            else if(this.props.user_exams.exam_code_error && this.props.user_exams.exam_code_error !== ""){
                is_allowed_to_exam = false;
                valid_user_code = false;
            }
            
            /* Update the state to display the exam questionnaire. */
            this.setState({ is_allowed_to_exam, valid_user_code });
        }

        /* This will update the uploaded file name in the state browse_and_drop_file_name once the new file name from props is returned. */
        if(this.props?.user_exams?.exam_details?.file_name && this.props?.user_exams?.exam_details?.file_name !== prevState.browse_and_drop_file_name){
            let browse_and_drop_file_name = this.props?.user_exams?.exam_details?.file_name;

            this.setState({ browse_and_drop_file_name });
        }
    }

    /**
    * DOCU: Handle the input change and update the state's value. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @param {object} event - Requires event to get the input value and target name.
    * @author Renz updated by Clifford
    */     
    handleInputChange = (event) => {
        const input_value = (event.target.value).replace(/^.*\\/, ""); /* This will replace the fakepath */
        let input_name = event.target.name;
        
        if(input_name === "file_url"){
            this.handleSubmitExamFile("file_url", event);
            this.setState({browse_and_drop_file_name: input_value});
        }
    }

    /**
    * DOCU: Handle the drag and drop event. <br>
    * Triggered: When drop files <br>
    * Last Updated Date: September 11, 2023
    * @function
    * @memberOf Exam
    * @param {object} event - Requires event of drag and drop.
    * @param {object} is_active_drag - Requires true or false when dragging.
    * @author Renz
    */
    handleDragAndDropEvent= (event, is_active_drag) =>{
        event.preventDefault();
        this.setState({is_active_drag: is_active_drag});
    }

    /**
    * DOCU: Handle the drop of the exam file. Will show uploading <br>
    * Triggered: When drop files <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @param {object} event - Requires event of drag and drop.
    * @author Renz updated by Clifford
    */
    handleDrop = (event) => {
        event.preventDefault();
        this.handleSubmitExamFile("drag_and_drop_file_url", event);

        /* Check if the file name exists; else, show an error. */
        if(event?.dataTransfer?.files[0]?.name) {
            this.setState({ browse_and_drop_file_name: event.dataTransfer.files[0].name, is_active_drag: false });
        }
    };
    
    /**
    * DOCU: Handle the submitted exam file. <br>
    * Triggered: When exam file is submitted <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @param {string} type - Identifies which type of submission
    * @param {object} event - Requires event of drag and drop.
    * @author Clifford
    */
    handleSubmitExamFile = (type, event) => {
        let exam_component = this;
        this.setState({ is_form_processing: true });
        
        /* Instantiate the form data object. */
        let formData = new FormData();

        /* Get the exam file based on the action taken. */
        const file_data = (type === "file_url") ? event.target.files[0] : event.dataTransfer.files[0];
        
        /* Prepare the necessary data */
        formData.append("file_data",file_data );
        formData.append("file_raw_name", file_data.name);
        formData.append("file_size", file_data.size);

        /* Send the request to the backend. */
        exam_component.props.submitExamFile(formData);

        /* Saving of File Upload Activity Log */
        this.props.onAddActivityLog('1.4.14');
    }

    /**
    * DOCU: Handle the validation of the exam code. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @param {object} event - Requires event to prevent form to submit.
    * @author Renz updated by Clifford
    */
    submitExamCodeForm = (event) => {
        event.preventDefault();
        let { user_code } = this.state;

        /* Set the is_submitting_exam_code to true for loading animation. */
        this.setState({ is_submitting_exam_code: true });

        /* This will send a request to the backend if the submitted code exists. */
        this.props.getExamDetails({ exam_code: user_code?.trim() });
    }

    /**
    * DOCU: This will return customized input for uploading the exam form. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @author Renz
    */
    customizeInput = () => {
        let { browse_and_drop_file_name } = this.state;

        return (
            <React.Fragment>
                <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>
                    <div id="customize_input_container" className={`${(!browse_and_drop_file_name) ? "is_empty" : ""}`}>
                        {(!browse_and_drop_file_name) ? <p>Select a file to upload</p> : ""}
                        <input id="upload_file_input" type="file" onChange={(event) => this.handleInputChange(event, this)} name="file_url"/>
                    </div>
                </MediaQuery>
                <MediaQuery minWidth={VIEW_WIDTH.DESKTOP}>
                    <p>Drag &amp; drop your files</p>
                    <input id="upload_file_input" type="file" onChange={(event) => this.handleInputChange(event, this)} name="file_url"/>
                </MediaQuery>
            </React.Fragment>
        );
    }

    /**
    * DOCU: This will handle the removal of errors in file uploads. <br>
    * Triggered: Inside render() <br>
    * Last Updated Date: January 15, 2024
    * @function
    * @memberOf Exam
    * @author Clifford
    */
    handleUploadFileError = () => {
        /* Remove the error message. */
        this.props.user_exams.file_upload_error = null;

        /* Remove the file name in state */
        this.setState({browse_and_drop_file_name: ""});
    }

    render() {
        let { valid_user_code, 
              user_code, 
              browse_and_drop_file_name, 
              exam,
              profile,
              is_allowed_to_exam,
              is_submitting_exam_code} = this.state;

        let { onAddActivityLog, 
              onAddBookmark, 
              onRemoveBookmark, 
              history, 
              is_dark_mode, 
              handleOnChangeDarkMode, 
              onUpdateUserSessionPage, 
              bookmarks, 
              location,
              user_exams,
              user} = this.props;
        
        let { due_date, time_entered, chapter_module_title, chapter_module_content, file_submission_date } = exam || {};
        let {is_uploading_file, file_upload_error, is_loading, exam_details} = user_exams;
        let { timezone } = user.user_details.general;

        return ( 
            <React.Fragment>
                <HeaderComponent
                    user_info={profile} 
                    onAddActivityLog={onAddActivityLog} 
                    onAddBookmark={onAddBookmark} 
                    onRemoveBookmark={onRemoveBookmark} 
                    history={history}
                    is_dark_mode={is_dark_mode}
                    handleOnChangeDarkMode={handleOnChangeDarkMode} 
                    onUpdateUserSessionPage={onUpdateUserSessionPage}/>
                <div className="container" id="exam_container">
                    <HeadComponent title={PAGE_TITLE.student_page.exams} />
                    <SubNavigationComponent 
                        bookmarks={bookmarks}
                        handleOnChangeDarkMode={handleOnChangeDarkMode}
                        history={history}
                        is_dark_mode={is_dark_mode}
                        is_show_links={true}
                        location={location.pathname} 
                        onAddActivityLog={onAddActivityLog}
                        onAddBookmark={onAddBookmark} 
                        onRemoveBookmark={onRemoveBookmark} 
                    />
                    
                    {(is_loading && !is_submitting_exam_code)
                        /* Show loader while fetching exam details */ 
                        ?   <img className="loading" src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/>

                        /* Exam Details */
                        :   (Object.keys(exam_details).length && due_date && time_entered)
                                ? <React.Fragment>
                                        <ExamContent
                                            exam_due_date={due_date}
                                            time_entered={time_entered}
                                            belt_exam={chapter_module_title}
                                            exam_content_json={chapter_module_content}
                                            workspace_timezone_acronym={timezone.acronym}
                                        />
                                        <div className="exam_container_section">
                                            <h3>Submit</h3>
                                            <p><b>Note:</b> Please <a href="https://www.wikihow.com/Make-a-Zip-File" target="_blank">Zip</a> your file(s) before uploading.</p>

                                            {(!browse_and_drop_file_name && !is_uploading_file && !file_upload_error) && 
                                                <form id="upload_exam_form"
                                                    className={`${(this.state.is_active_drag) ? "is_active_drag" : ""} ${this.state.is_form_processing ? "disable_form" : ""}`}
                                                    onDrop={(event) => this.handleDrop(event)}
                                                    onDragOver={(event) => this.handleDragAndDropEvent(event, true)}
                                                    onDragEnter={(event) => this.handleDragAndDropEvent(event, true)}
                                                    onDragLeave={(event) => this.handleDragAndDropEvent(event, false)}>
                                                    <img src={`https://assets.codingdojo.com/learn_platform/user/courses/${(is_dark_mode) ? "dark_mode_upload_icon.png" : "upload_icon.png"}`} alt="upload-icon" />
                                                    {/* This will render customized input. */}
                                                    {this.customizeInput()}
                                                    <label htmlFor="upload_file_input">or <span>Browse</span></label>
                                                    <MediaQuery maxWidth={VIEW_WIDTH.MOBILE}>
                                                        <button type="submit" className={`${(!browse_and_drop_file_name) ? "disabled" : ""}`}>Submit Assignment</button>
                                                    </MediaQuery>
                                                </form>
                                            }

                                            {/* This will show a successfully submitted file exam. */}
                                            {(browse_and_drop_file_name && !file_upload_error && !is_uploading_file) &&
                                                <div id="exam_submitted_container">
                                                    <img src={`https://assets.codingdojo.com/learn_platform/user/courses/${(is_dark_mode) ? "dark_mode_uploaded_file_icon.png" : "uploaded_file_icon.png"}`} alt="upload-icon" />
                                                    <div id="exam_submission_details">
                                                        <p> 
                                                            <a id="file_download" target="_blank" href={`${user_exams.exam_details.file_presigned_url}`} download="">{browse_and_drop_file_name}</a>
                                                        </p>
                                                        <span>Successfully submitted on {moment(file_submission_date).utcOffset(timezone.value).format("MMMM D, YYYY")}</span>
                                                    </div>
                                                    <button type="button" onClick={()=>{this.setState({browse_and_drop_file_name: ""}); user_exams.exam_details.file_name = ""}}></button>
                                                </div>
                                            }

                                            {/* This will show the uploading file exam. */}
                                            {(is_uploading_file && !file_upload_error) &&
                                                <div id="is_uploading_container">
                                                    <img src={`https://assets.codingdojo.com/learn_platform/user/courses/${(is_dark_mode) ? "dark_mode_uploaded_file_icon.png" : "uploaded_file_icon.png"}`} alt="upload-icon" />
                                                    <p>Uploading file. Please wait. <span className="loading_icon"></span></p>
                                                </div>
                                            }

                                            {/* This will show an error on uploading the file.*/}
                                            {(file_upload_error && !is_uploading_file) &&
                                                <form id="re_upload_assignment_form" 
                                                    className={`${(this.state.is_active_drag) ? "is_active_drag" : ""} ${this.state.is_form_processing ? "disable_form" : ""}`}
                                                    onDrop={(event) => this.handleDrop(event)}
                                                    onDragOver={(event) => this.handleDragAndDropEvent(event, true)}
                                                    onDragEnter={(event) => this.handleDragAndDropEvent(event, true)}
                                                    onDragLeave={(event) => this.handleDragAndDropEvent(event, false)}>
                                                    <img src="https://assets.codingdojo.com/learn_platform/user/courses/re_upload_file_icon.png" alt="upload-icon" />
                                                    <p>{ file_upload_error }</p>
                                                    <input id="upload_file_input" type="file" onChange={(event) => this.handleInputChange(event)} name="file_url"/>
                                                    <button type="button" onClick={()=>{this.handleUploadFileError()}}>Upload Again</button>
                                                </form>
                                            }
                                            
                                        </div>
                                  </React.Fragment>

                                : <React.Fragment>
                                        <div className="exam_container_section" id="exam_code_container">
                                                <h3>Enter your Exam Code</h3>
                                                <form method="post" 
                                                    id="exam_code_form" 
                                                    onSubmit={this.submitExamCodeForm}
                                                    className={`${(valid_user_code !== null && !valid_user_code) ? "has_error" : ""}`}>
                                                    <input type="text" onChange={(event) => handleInputChange(event, this)} name="user_code" value={user_code} placeholder="Enter exam code"/>
                                                    <button name="submit" type="submit" className={ user_code ? "" : "disabled"}>Unlock</button>
                                                </form>
                                                {(valid_user_code !== null && !valid_user_code) ? <p>{user_exams.exam_code_error}</p> : ""}
                                        </div>
                                  </React.Fragment> 
                    }
                </div>
            </React.Fragment>  
         );
    }
}

let { getExamDetails, fetchTimeEntered, submitExamFile } = UserExamsActions;
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(["exams", "user_exams", "user", "exam_file"], { getExamDetails, fetchTimeEntered, submitExamFile });
export default connect(mapStateToProps, mapDispatchToProps)(Exam);