/* React */
import React 						from "react";
import ReactDOM 					from "react-dom";
import { store } 					from "./__config/store";
import { getUserDetailsFromToken } 	from "./__helpers/helpers";
import { LOCAL_ENV, APP_SETTINGS } 	from "./__config/constants";
import * as serviceWorker 			from "./serviceWorker";
/* Components */ 
import UserLayout 					from "./views/layouts/user.layout.jsx";
import AdminLayout 					from "./views/layouts/admin.layout.jsx";

/* Plugins */ 
import * as Sentry 					from "@sentry/react";
import { Integrations } 			from "@sentry/tracing";
import { Provider } 				from "react-redux";
import { library } 					from "@fortawesome/fontawesome-svg-core";
import { fas } 						from "@fortawesome/free-solid-svg-icons";
import { far } 						from "@fortawesome/free-regular-svg-icons";

/* CSS */ 
import "./assets/css/global.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/vendor/monokai-soda-rainbow-theme.min.css";
import "./index.scss";
import "./is_dark_mode.scss";

library.add(fas, far);


/* Sentry error logger */ 
if(process.env.REACT_APP_SENTRY_ENV !== LOCAL_ENV){
    Sentry.init({ 
        dsn: "https://bdc02751f3a044fb8607a430793a5f4d@o351953.ingest.sentry.io/5551526",
        environment: process.env.REACT_APP_SENTRY_ENV,
        autoSessionTracking: true,
        integrations: [
            new Integrations.BrowserTracing(),
        ],
        ignoreErrors:[
            "Network Error",
            "Please select atleast 1 filter.",
            "acsbJS is not defined",
            "This video does not exist.",
            "NetworkError when attempting to fetch resource.",
            "This video does not exist.",
            "Invalid or unexpected token", 
            "Failed to fetch",
            "Cannot read properties of undefined (reading 'remove')",
            "Non-Error promise rejection captured with value: You don't have the right permission to access.",
            "Non-Error promise rejection captured with keys: [object has no keys]",
            "undefined is not an object (evaluating 's[e.responseRequestID].functions')",
            "Cannot read properties of null (reading 'offsetHeight')",
            "Cannot read properties of undefined (reading 'general')",
            "Non-Error promise rejection captured with value: Object Not Found Matching Id:4",
            "Cannot read properties of undefined (reading 'parentElement')",
            "Cannot read properties of null (reading 'classList')",
			"Can't find variable: $",
			"$ is not defined",
			"Cannot read properties of null (reading 'removeChild')",
			"Cannot read properties of null (reading 'document')",
			"undefined is not an object (evaluating 't.parentElement')",
			"t is undefined",
			"Cannot read properties of null (reading 'ownerDocument')",
			"undefined is not an object (evaluating 'o.general')",
			"o is undefined"
        ]
    });

	let get_user_details = getUserDetailsFromToken();
	let user_email = "Anonymous";

	if(get_user_details.status){
		user_email = get_user_details.user_details.general.email_address
	}

	Sentry.configureScope(function(scope) {
		scope.setUser({"email": user_email});
	});
}

/* Avoid clickjacking on client side */ 
if(window.self === window.top) {
	ReactDOM.render(
		<React.StrictMode>
			<React.Fragment>
				<Provider store={store}>
					{ window.location.pathname.indexOf("modules/edit") === -1 && window.location.pathname.indexOf("admin/") === -1 && window.location.pathname.indexOf("bug") === -1
						? <UserLayout/> 
						: <AdminLayout/>          
					}
				</Provider>
			</React.Fragment>
		</React.StrictMode>,
		document.getElementById('root')
	);
}
/* Warning Page for accessing the site using iframe */ 
else{
	ReactDOM.render(
		<React.Fragment>
			<h1 style={{ color: "red" }}>If you see this page, The CD Learning Platform link you have clicked on is under Clickjacking security attack.</h1>
			<h2>Please inform team with the reference of the application from where you clicked this link.</h2>
			<h2>Click <a href={window.self.location.href} title="Learning Platform" target="blank">here</a> to access the Learning Platform safely.</h2>
		</React.Fragment>,
		document.getElementById('root')
	);
}

serviceWorker.unregister();