/* React */
import React, { Component }                                         from "react";
/* Plugins */ 
import { FontAwesomeIcon }                                          from "@fortawesome/react-fontawesome";
import MediaQuery                                                   from "react-responsive";
import { PopupModal }                                               from "react-calendly";
import { NavDropdown }                                              from "react-bootstrap";
import { connect }                                                  from "react-redux";
/* Helpers */ 
import { mapAnddispatchActionsToProps, 
         history, 
         getUserDetailsFromToken, 
         toggleShowModal, 
         getAdminURL, 
         checkUserCapabilities }                                    from "../../../__helpers/helpers";
/* Actions */
import { UserActions }                                              from "../../../__actions/user.actions";
import { DashboardActions }                                         from "../../../__actions/dashboard.actions";
import { AdminActions }                                             from "../../../__actions/admin.actions";
import { FileUploadActions }                                        from "../../../__actions/file_upload.actions";
/* Constants */
import { TRUE_VALUE, 
         UserLevelIds, 
         ADMIN_USERS, 
         PLATFORM_TOUR,
         CRM_STATUSES, 
         WORKSPACE_IDS
        }                                                           from "../../../__config/constants";
/* Components */ 
import MyFilesModal                                                 from "./../../user/modals/my_files.modal";
import ResetPasswordModal                                           from "./../../user/modals/reset_password.modal";
import StopSenceSessionModal                                        from "../../user/modals/chile_sence/stop_sence_session.modal";
/* CSS */ 
import './profile.component.scss';

/** 
* @class 
* @extends Component
* This component class is being called on the /global/components/header.component.jsx. Responsible for student profile. <br>
* All methods are related to user profile. <br>
* Last Updated Date: September 27, 2023
*/

class UserProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_show_reset_password_modal: false,
            is_show_my_files_modal: false,
            is_uploading_profile_pic: false,
            is_show_profile: false,
            is_show_special_request_form_modal: false,
            admin_default_url: "",
            is_show_stop_sence_modal: false
        }
              
        let get_user_details = getUserDetailsFromToken();

        /* Will show student walk through */
        if(get_user_details.status === true){
            let is_skip_walkthrough = localStorage.getItem("SKIP_WALKTHROUGH");

            this.state = {profile: get_user_details.user_details}
            /* Temporary implementation for notification feature */ 
            this.state.profile.show_notification_indicator =  true;

            if(is_skip_walkthrough && get_user_details.user_details.workspace_custom_data.customizations.is_show_platform_demo === true && (["?startProductTour=true", "?multipage=true"].includes(window.location.search) === false) ){
                localStorage.setItem("SKIP_WALKTHROUGH", true);
                window.location.href = "/dashboard?startProductTour=true";
            }
        }
        else{
            this.userLogout();
            history.push("/login");
        }
    }

    componentDidMount = () => {
        const url_parameter = new URLSearchParams(window.location.search);
        const is_start_product_tour = eval(url_parameter.get("startProductTour"));
        
        /* Will hide profile modal and show the last platform demo */
        if(is_start_product_tour && parseInt(url_parameter.get("step")) === PLATFORM_TOUR.RESET_PASSWORD){
            document.getElementById("show_profile_modal").click();
            document.getElementsByTagName("body")[0].className = "is_last_platform_demo";
        }

        let admin_default_url = getAdminURL(this.state.profile?.general?.user_capabilities, "", true);

        this.setState({ admin_default_url });
    }

    /**
    * DOCU: This function will logout the user.
    *       February 19, 2022 - JeffreyCarl: Added logic that when the user has active Sence session, show stop sence modal first. <br>
    * Triggered: User profile sud nav. #sticky_footer_container  <br>
    * Last Updated Date: September 7, 2023
    * @function
    * @memberOf User profile
    * @author Noah, Updated by: JeffreyCarl, Demy
    */
    userLogout = () => {
        if(this.state.profile?.workspace_custom_data?.sence_integration?.is_sence_session_on){
            this.setState({is_show_stop_sence_modal: true});
        }
        else{
            window.location.href = (this.props.user.user_details.general.trial_platform_mode) ? "/t/logout" : "/logout";
        }
    }

    /**
    * DOCU: Getting the Enroll App link and redirecting the user to Enroll App <br>
    * Triggered: render()  <br>
    * Last Updated Date: September 27, 2023
    * @function
    * @memberOf User profile
    * @param {Object} event
    * @param {Boolean} make_payment
    * @param {Boolean} is_special_request
    * @param {Boolean} is_student_information
    * @author Jaybee, Updated by: JeffreyCarl
    */
    redirectToEnrollApp = (event, is_make_payment, is_special_request = false, is_student_information = false) => {
        event.preventDefault();

        /* (User Profile) User clicks 'My Files'/'Mattermost'/'Refer a Friend'/'Reset Password'/'My Payment'/'Student Information' link. */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.3");
        this.props.get_enroll_app_link(is_make_payment, is_special_request, is_student_information);
    }
    
    /**
    * DOCU: Show the profile modal and save the activity of the users. <br>
    * Triggered:  profile button <br>
    * Last Updated Date: November 16, 2020
    * @function
    * @memberOf User profile
    * @author Christian, Updated by Jerwin
    */
    displayProfileModal = () => {
        /* User clicks the user profile and opens the profile view */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.6"); 
        this.setState({ is_show_profile: !this.state.is_show_profile })
    }

    /**
    * DOCU: Will show My Files Modal and save the activity of the users. <br>
    * Triggered:  My Files Link<br>
    * Last Updated Date: May 27, 2022
    * @function
    * @memberOf User profile
    * @author Christian, updated by: Cesar
    */
    displayMyFilesModal = () => {
        toggleShowModal(this, "is_show_my_files_modal", true);

        /* Call function from props that will fetch user private files*/
        this.props.fetch_private_files();

        /* (User Profile) User clicks 'My Files'/'Mattermost'/'Refer a Friend'/'Reset Password'/'My Payment' link */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.3");
    }

    /**
    * DOCU: Will save the activity of the users for mattermost. <br>
    * Triggered:  'Mattermost' link <br>
    * Last Updated Date: April 12, 2021
    * @function
    * @memberOf User profile
    * @author Christian
    */
    redirectToMattermost = () => {
        /* (User Profile) User clicks 'My Files'/'Mattermost'/'Refer a Friend'/'Reset Password'/'My Payment' link */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.3");
    }

    /**
    * DOCU: Will save the activity of the users for referral. <br>
    * Triggered:  'Refer a Friend' link <br>
    * Last Updated Date: April 12, 2021
    * @function
    * @memberOf User profile
    * @author Christian
    */
    redirectToReferralPage = () => {
        /* (User Profile) User clicks 'My Files'/'Mattermost'/'Refer a Friend'/'Reset Password'/'My Payment' link */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.3");
    }

    /**
    * DOCU: Will show the reset modal and save the activity of the users. <br>
    * Triggered:  'Refer a Friend' link <br>
    * Last Updated Date: April 12, 2021
    * @function
    * @memberOf User profile
    * @author Christian
    */
    displayResetPasswordModal = () => {
        toggleShowModal(this, "is_show_reset_password_modal", true)

        /* (User Profile) User clicks 'My Files'/'Mattermost'/'Refer a Friend'/'Reset Password'/'My Payment' link */
        this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.3");
    }

    /**
    * DOCU: This will handle the uploading/changing of profile picture and save the activity logs of the user. <br>
    * Triggered:render(), click current profile picture from user details sidebar <br>
    * Last Updated Date: Feb 25, 2021
    * @function
    * @memberOf User profile
    * @param {object} event ="" - Require event object
    * @author Demy, Updated by: Jessie
    */
    handleUploadedProfilePicture = (event) => {
		if(event.target.files.length != 0){
            this.setState({is_uploading_profile_pic: true});
            this.props.upload_user_profile_pic({pic_upload: event.target.files[0]});

            /* (Profile Modal) User uploads a profile picture */
            this.props.onAddActivityLog !== undefined && this.props.onAddActivityLog("3.2.14");
		}
    }

    /**
    * DOCU: This will set the run walkthrough to true and redirect to dashboard. <br>
    * Triggered: When Platform Demo is clicked. <br>
    * Last Updated Date: June 26, 2023
    * @function
    * @memberOf User profile
    * @author Jhones
    */
    runPlatformDemo = () => {        
        this.setState({ is_show_profile: !this.state.is_show_profile });
        window.location.href = "/dashboard?startProductTour=true";
    }

    render() { 
        let { general: { email_address, first_name, last_name, user_level_id, access_expiration, user_capabilities }, workspace_custom_data, workspace } = this.state.profile;
        let user_image_url = this.props.user?.user_details?.general?.image_url || null;
        let bootcamp_info  = workspace_custom_data?.bootcamp_info || {};
        let enrollment     = workspace_custom_data?.enrollment || {};
        let { admin_default_url, is_show_profile } = this.state;
        
        return ( 
            <React.Fragment>
                <NavDropdown className={`profile_nav_dropdown ${is_show_profile ? "show" : ""}`} id="show_profile_modal" title={
                            <img src={user_image_url || "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={(user_image_url) ? `${first_name } ${last_name}` : "user icon"}/>
                            } onClick={(event) => this.displayProfileModal()}>
                    <div id="basic_info_container">
                        <MediaQuery maxWidth={768}>
                            <button type="button" onClick={(event) => document.getElementById("show_profile_modal").click()} id="close_right_nav_btn"><FontAwesomeIcon icon={["fas", "chevron-up"]} /></button>
                        </MediaQuery>
                        <label htmlFor="upload_profile_pic_input"
                            title="Upload Profile Picture"
                            className={(this.props.user.is_uploading_profile_pic) ? "is_uploading" : "" }>
                            <input id="upload_profile_pic_input" 
                                onChange={(event) => this.handleUploadedProfilePicture(event)}
                                type="file" 
                                accept="image/*"/>
                            <FontAwesomeIcon icon={["fas", "upload"]} />
                            <img className="loading" src={"https://assets.codingdojo.com/learn_platform/global/loader.gif"} alt="loading icon"/>
                            <img src={(user_image_url) ? user_image_url : "https://assets.codingdojo.com/learn_platform/global/profile_placeholder.png"} alt={(user_image_url) ? `${first_name } ${last_name}` : "user icon"}/>
                        </label>   

                        <div className="name_label">
                            <p className="name">{first_name} {last_name}</p>
                            <p>{email_address}</p>
                        </div>
                        {/* Hide for now because there is no page yet */}
                        {/* <button id="manage_account_button">Manage Your Student Profile</button> */}
                    </div>

                    <div id="profile_navigation_container">
                         {/* Student Information. Only show link to users who undergone enrollment process and atleast has paid deposit. Added condition to only show link to users in CD Domestic. */}
                         { (enrollment.latest_application_status >= CRM_STATUSES.paid_deposit && !!enrollment.has_payment_record && workspace.workspace_id === WORKSPACE_IDS.codingdojo) && 
                           <a target="_blank" onClick={(event) => this.redirectToEnrollApp(event, false, false, true)} href="#"><span className="profile_btn_icon user_icon"></span>Student Information</a>
                        }

                        {/* Admin View */}
                        { (this.state.profile && ADMIN_USERS.includes(user_level_id) && checkUserCapabilities(user_capabilities, "admin.visibility")) && 
                            <a href="#" onClick={(e) => { 
                                e.preventDefault(); 
                                this.props.navigateToLP2Admin(); 
                            }}>Admin View (LP2)</a>
                        }
                        { (this.state.profile && ADMIN_USERS.includes(user_level_id) && checkUserCapabilities(user_capabilities, "admin.visibility")) && 
                            <a href={admin_default_url}>Admin View (LP3)</a>
                        }
                        {/* My Payment */}
                        { (enrollment && enrollment.has_payment_record === TRUE_VALUE) && 
                            <button type="button" onClick={(event) => this.redirectToEnrollApp(event, false)}>My Payment</button>
                        }
                        {/* My Files */}
                        { this.props.user && 
                            <button type="button" onClick={(event) => this.displayMyFilesModal()} >My Files</button>
                        }
                        
                        {/* Special Request Form */}
                        { (enrollment && enrollment.has_payment_record === TRUE_VALUE) &&
                            <React.Fragment>
                                <button type="button" onClick={() => this.setState({ is_show_special_request_form_modal: true })}>Special Request Form</button>

                                <PopupModal
                                    url="https://calendly.com/d/cn9-4p8-843?hide_gdpr_banner=1"
                                    onModalClose={() => this.setState({ is_show_special_request_form_modal: false })}
                                    open={this.state.is_show_special_request_form_modal}
                                    rootElement={document.getElementById("root")}
                                />
                            </React.Fragment>
                        }
                        {/* Refer a Friend */}
                        { (this.state.profile && [UserLevelIds.STUDENT, UserLevelIds.GRADUATE, UserLevelIds.ALUMNI_PASS_USER].includes(user_level_id) === true) &&
                            <React.Fragment>
                                <a target="_blank" onClick={(event) => this.redirectToReferralPage()} href="https://www.codingdojo.com/referral">Refer a Friend</a>
                            </React.Fragment>
                        }
                     
                        {!this.props.user.user_details?.general?.trial_platform_mode &&
                            <React.Fragment>
                                   {/* Reset Password*/}
                                    <button id="show_reset_password_btn"
                                        type="button"
                                        onClick={(event) => this.displayResetPasswordModal()}><span className="profile_btn_icon lock_icon"></span>Reset Password</button>
                                    {/* Platform Demo*/}
                                    <MediaQuery minWidth={769}>
                                        <button type="button" onClick={this.runPlatformDemo}>Platform Demo</button>
                                    </MediaQuery>

                            </React.Fragment>
                            
                        }
                    </div>

                    <div id="profile_nav_footer">
                         {/* Log Out*/}
                        <button type="button" onClick={this.userLogout}>Sign Out</button>
                    </div>
                </NavDropdown>
                
                { this.props.user && this.props.user?.user_private_files && 
                    <MyFilesModal 
                        show={this.state.is_show_my_files_modal} 
                        is_dark_mode={this.props.is_dark_mode}
                        user_files={this.props.user?.user_private_files}
                        generateS3Url={this.props.generateS3Url}
                        clearPresignedURL={this.props.clearPresignedURL}
                        dashboard={this.props.dashboard}
                        toggleShowModal={(event) => toggleShowModal(this, "is_show_my_files_modal", false)} />
                }

                <ResetPasswordModal 
                    is_dark_mode={this.props.is_dark_mode}
                    show={this.state.is_show_reset_password_modal} 
                    toggleShowModal={(event) => toggleShowModal(this, "is_show_reset_password_modal", false)}
                    onAddActivityLog={this.props.onAddActivityLog}/>

                { this.state.profile?.workspace_custom_data?.sence_integration && 
                <StopSenceSessionModal 
                    history={this.props.history}
                    show={this.state.is_show_stop_sence_modal} 
                    toggleShowModal={() => toggleShowModal(this, "is_show_stop_sence_modal", false)}
                    user_details={this.state.profile}
                    is_logout={true}/>
                }
                
            </React.Fragment>
         );
    }
}
 
const {mapStateToProps, mapDispatchToProps} = mapAnddispatchActionsToProps(['user', 'dashboard', 'notification'], {
    logout: UserActions.logout,
    upload_user_profile_pic: UserActions.upload_user_profile_pic,
    get_enroll_app_link: DashboardActions.get_enroll_app_link,
    fetch_private_files: UserActions.fetchPrivateFiles,
    navigateToLP2Admin: AdminActions.navigateToLP2Admin,
    generateS3Url: FileUploadActions.generateS3Url,
    clearPresignedURL: FileUploadActions.clearPresignedURL
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);